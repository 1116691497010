'use client';
import { useClientSideEffect } from 'hooks/useClientSideEffect';
import { Map as MapboxGLMap, Marker } from 'mapbox-gl';
import { MapControllers } from 'models/MapControllers';
import { createContext, createElement, useCallback, useContext } from 'react';
import { truckMapConfig } from 'truckMapConfig';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DOM_API = truckMapConfig.isClientSideRender && (window as any);

export type TruckMapGL = MapboxGLMap & { markersCache?: Marker[] };

type MapGLContextType = Map<MapControllers, TruckMapGL>;

const MapGLCache = new Map();
export const MapGLContext = createContext<MapGLContextType>(MapGLCache);

export const MapGLProvider: React.ComponentType<ChildrenProp> = ({ children }) =>
  createElement(MapGLContext.Provider, { value: MapGLCache }, children);

export const useMapGLContext = () => useContext(MapGLContext);

export const useMapGLController = (controllerName: MapControllers) =>
  useMapGLContext().get(controllerName);

export const useMapEffect = (
  callback: (map: TruckMapGL) => void,
  mapControllerId: MapControllers
) => {
  useClientSideEffect(() => {
    const eventName = `map.${mapControllerId}.load`;
    const cb = ({ detail: mapbox }: CustomEvent<MapboxGLMap>) => {
      callback(mapbox);
    };

    document.addEventListener(eventName, cb);

    return () => {
      document.removeEventListener(eventName, cb);
    };
  });
};

export const getMapInstance = (controllerName: MapControllers) =>
  DOM_API?.truckmapGL && (DOM_API.truckmapGL[controllerName] as TruckMapGL);

export const useGetMapInstance = (controllerName: MapControllers) => {
  return useCallback(() => getMapInstance(controllerName), [controllerName, DOM_API?.truckmapGL]);
};
