import baseConfig from './baseConfig.json';

type VercelEnv = 'production' | 'preview' | 'development';

type TruckMapConfig = typeof baseConfig & {
  apiKeys: {
    pusher: {
      appKey: string;
    };
    contentful: {
      accessToken: string;
      space: string;
    };
    gtm: {
      id: string;
    };
    sentry: {
      dsn: string;
    };
  };
  apiRoot: string;
  appVersion: string;
  url: string;
  env: VercelEnv;
  guestDeviceSecret: string;
  isTenstreet: boolean;
  isStaging: boolean;
  isClientSideRender: boolean;
  isSearchBot: boolean;
  isSmoothScrollSupported: boolean;
  mapBoxToken: string;
  mapBoxStyle: string;
  defaultHubSpotFormId: string;
  defaultHubSpotPortalId: string;
  responsiveBreakpoints?: {
    [size: string]: number;
  };
  places: {
    nearbyLimit: number;
    reviewLimit: number;
  };
  tenstreetCoordinates: [number, number];
};

const isClientSideRender = typeof window !== 'undefined';
const isLocalHost = isClientSideRender && window?.location?.hostname === 'localhost';

const isSmoothScrollSupported =
  isClientSideRender && 'scrollBehavior' in document.documentElement.style;

const env = process.env.NEXT_PUBLIC_VERCEL_ENV as VercelEnv;
const isTenstreet = process.env.NEXT_PUBLIC_TENSTREET_BUILD === 'true';
const isVercel = Boolean(process.env?.NEXT_PUBLIC_VERCEL_URL?.length);

const getDevelopmentUrl = (url: string) => url.replace(/\.com\b/, '.dev');

const tenstreetBaseUrlDevelopment = getDevelopmentUrl(baseConfig.tenstreetUrl);
const baseUrlDevelopment = getDevelopmentUrl(baseConfig.baseUrl);
const localHostUrl = 'http://localhost:3000';

let url;

switch (process.env.NEXT_PUBLIC_VERCEL_ENV) {
  case 'production':
    url = isTenstreet ? baseConfig.tenstreetUrl : baseConfig.baseUrl;
    break;
  case 'development':
    url = isLocalHost
      ? localHostUrl
      : isTenstreet
      ? tenstreetBaseUrlDevelopment
      : baseUrlDevelopment;

    break;
  case 'preview':
    url = isTenstreet ? tenstreetBaseUrlDevelopment : baseUrlDevelopment;
    break;
  default:
    url = isVercel
      ? `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`
      : isClientSideRender
      ? `https://${window.location.hostname}`
      : localHostUrl;
}

export const truckMapConfig: TruckMapConfig = {
  ...baseConfig,
  appVersion: process.env.NEXT_PUBLIC_APP_VERSION,
  apiKeys: {
    pusher: {
      appKey: process.env.NEXT_PUBLIC_PUSHER_APP_KEY
    },
    contentful: {
      accessToken: process.env.CONTENTFUL_ACCESS_TOKEN,
      space: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID
    },
    gtm: {
      id: process.env.NEXT_PUBLIC_GTM_ID
    },
    sentry: {
      dsn: process.env.NEXT_PUBLIC_SENTRY_DSN
    }
  },
  places: {
    nearbyLimit: 5,
    reviewLimit: 10
  },
  isTenstreet,
  tenstreetCoordinates: [-95.992775, 36.15398],
  isStaging: env === 'development' || env === 'preview',
  isClientSideRender,
  isSmoothScrollSupported,
  isSearchBot:
    isClientSideRender &&
    typeof navigator !== 'undefined' &&
    /bot|googlebot|crawler|spider|robot|crawling/i.test(window.navigator.userAgent),
  mapBoxToken: process.env.NEXT_PUBLIC_MAPBOX_TOKEN,
  mapBoxStyle: process.env.NEXT_PUBLIC_MAPBOX_STYLE,
  defaultHubSpotFormId: process.env.NEXT_PUBLIC_HUBSPOT_FORM_ID,
  defaultHubSpotPortalId: process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID,
  apiRoot: process.env.NEXT_PUBLIC_API_ROOT,
  guestDeviceSecret: process.env.NEXT_PUBLIC_GUEST_DEVICE_SECRET,
  env,
  url
};
